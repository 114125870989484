import React from "react";
import "./App.scss";
import DevPage from './components/DevelopmentPage/DevelopmentPage';

const App = () => {
	return (
		<div>
			<DevPage/>
		</div>
	);
}
export default App;
